.banner {
  position: relative;

  display: block;

  color: var(--color-black);
  text-align: left;

  &:before {
    display: block;
    padding: 0 0 calc(100% / 2 - var(--space-s));

    content: '';
  }

  &.inversed {
    color: var(--color-white);
  }

  &.textAlignTop {
    .contentWrapper {
      justify-content: flex-start;
    }
  }
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding-bottom: 0;
}

.contentWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  max-width: 50%;

  padding: var(--space-xxl);
  overflow: hidden;
}

.title {
  margin-bottom: var(--space-s);

  font-size: var(--size-b);
  line-height: 1.5;
}

.subtitle {
  font-size: var(--size-l);
  line-height: 1.5;
}

.link {
  display: inline-block;
  width: fit-content;
  margin-top: var(--space-l);

  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xxl);
  }

  .subtitle {
    font-size: var(--size-m);
  }

  .contentWrapper {
    padding: var(--space-l);
  }
}

@media (--desktop) {
  .banner:before {
    padding: 0 0 calc(100% / 2 - var(--space-xs));
  }
}

@media (--mobile-m) {
  .banner {
    width: calc(100% + 30px);

    transform: translateX(-15px);
  }

  .banner:before {
    padding: 0 0 301px;
  }

  .contentWrapper {
    padding: var(--space-l) var(--space-xb);
  }

  .title {
    font-size: var(--size-xl);
  }

  .subtitle,
  .link {
    font-size: var(--size-n);
  }
}

@media (--mobile) {
  .banner {
    width: 100%;
    max-width: 100%;
    max-height: 301px;

    transform: none;
    &:before {
      padding: 0 0 46.156%;
    }
  }

  .contentWrapper {
    padding: var(--space-n) var(--space-s);
  }
}

@media (--mobile-xs) {
  .banner {
    height: 180px;
  }

  .title {
    margin-bottom: var(--space-xxs);

    font: var(--font-main);
    font-weight: 500;
    font-size: var(--size-n);
  }

  .subtitle,
  .link {
    font-size: var(--size-s);
  }

  .link {
    margin-top: var(--space-xs);
  }
}
